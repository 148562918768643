import { FunctionComponent, useEffect } from "react";
import Button from "./Button";

export type FrameComponentType = {
  className?: string;
};

const FrameComponent: FunctionComponent<FrameComponentType> = ({
  className = "",
}) => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div
      className={`flex-1 flex flex-col items-start justify-start gap-[43px] max-w-full text-left text-36xl text-white-white font-typography-desktop-h5 mq750:gap-[21px] ${className}`}
    >
      <h1
        className="m-0 self-stretch relative text-inherit font-medium font-inherit [&.animate]:animate-[1s_ease_0s_1_normal_forwards_fade-in] opacity-[0] z-[1] mq450:text-14xl mq750:text-25xl"
        data-animate-on-scroll
      >
        A solução definitiva de Superfície de Ataque
      </h1>
      <div className="w-[546px] flex flex-row items-start justify-start pt-0 px-0 pb-9 box-border max-w-full text-7xl text-neutrals-neutrals-5">
        <h2
          className="m-0 flex-1 relative text-inherit font-normal font-inherit inline-block [&.animate]:animate-[3s_ease_0s_1_normal_forwards_fade-in] opacity-[0] max-w-full z-[1] mq450:text-2xl"
          data-animate-on-scroll
        >
          <p className="m-0">{`A Glider Security está vindo pra ficar! `}</p>
          <p className="m-0">
            Nossa missão é ajudar negócios a visualizar, entender e controlar
            suas superfícies de ataque cibernético.
          </p>
        </h2>
      </div>
      <div className="w-[625px] flex flex-col items-start justify-start gap-[18px] max-w-full text-xl">
        <div
          className="self-stretch relative whitespace-pre-wrap [&.animate]:animate-[6s_ease_0s_1_normal_forwards_fade-in] opacity-[0] z-[1] mq450:text-base"
          data-animate-on-scroll
        >
          Entre na lista de espera para acesso antecipado e descontos 🎉
        </div>
        <div className="w-[405px] flex flex-row flex-wrap items-start justify-start gap-[26px] max-w-full">
          <form action="https://submit-form.com/evZjjHpDD" className="w-[405px] flex flex-row flex-wrap items-start justify-start gap-[26px] max-w-full">
            <input
              className="w-full [outline:none] bg-brand-dark flex-1 rounded-md box-border flex flex-col items-start justify-start pt-[11px] px-[19px] pb-2.5 font-body-medium-medium text-base text-dark-dark-6 min-w-[162px] border-[1px] border-solid border-stroke"
              placeholder="Digite seu email"
              type="email" id="email" name="email"
            />
            <Button type="submit" buttonText="Me avise!" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent;
