import { FunctionComponent, useCallback, useEffect } from "react";

export type ButtonType = {
  className?: string;
  buttonText?: string;
  type?: string;
};

const Button: FunctionComponent<ButtonType> = ({
  className = "",
  buttonText = "Me avise!",
  type = "submit",
}) => {
  const onButtonClick = useCallback(() => {
    //TODO: https://submit-form.com/evZjjHpDD
  }, []);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <button
      className={`cursor-pointer [border:none] py-[13px] px-7 bg-primary-green-primary-green-500 rounded-md flex flex-row items-start justify-start [&.animate]:animate-[8s_ease_0s_1_normal_forwards_fade-in] opacity-[0] whitespace-nowrap z-[1] hover:bg-mediumseagreen ${className}`}
      onClick={onButtonClick}
      data-animate-on-scroll
    >
      <div className="relative text-base leading-[24px] font-medium font-body-medium-medium text-neutrals-neutrals-12 text-center inline-block min-w-[73px]">
        {buttonText}
      </div>
    </button>
  );
};

export default Button;
