import { FunctionComponent, useEffect } from "react";
import KindPrimaryColorTertiary from "./KindPrimaryColorTertiary";

export type NavbarsType = {
  className?: string;
};

const Navbars: FunctionComponent<NavbarsType> = ({ className = "" }) => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <header
      className={`self-stretch bg-brand-dark overflow-hidden flex flex-row items-start justify-center pt-[9px] px-5 pb-2.5 box-border [&.animate]:animate-[1s_ease_0s_1_normal_forwards_fade-in] opacity-[0] sticky top-[0] z-[99] max-w-full text-left text-base text-brand-white font-body-medium-medium ${className}`}
      data-animate-on-scroll
    >
      <div className="h-[100px] w-[1440px] relative hidden max-w-full">
        <div className="absolute top-[0px] left-[0px] bg-brand-dark w-full h-full hidden" />
        <div className="absolute top-[29px] right-[45px] hidden flex-row items-center justify-start py-0 pr-[9px] pl-0">
          <KindPrimaryColorTertiary
            buttonText="Login"
            kindPrimaryColorTertiaryBackgroundColor="#f2fdf7"
            kindPrimaryColorTertiaryBorder="1px solid #f2fdf7"
            kindPrimaryColorTertiaryPadding="7px 23px"
            kindPrimaryColorTertiaryFlex="1"
          />
        </div>
        <div className="absolute top-[38px] left-[45px] hidden flex-row items-start justify-start gap-[40px]">
          <div className="self-stretch relative leading-[24px] font-medium text-primary-green-primary-green-50 shrink-0">
            Home
          </div>
          <div className="self-stretch relative leading-[24px] font-medium shrink-0">
            Sobre
          </div>
          <div className="self-stretch relative leading-[24px] font-medium shrink-0">
            Funcionalidades
          </div>
          <div className="self-stretch relative leading-[24px] font-medium shrink-0">
            Preços
          </div>
        </div>
      </div>
      <img
        className="h-[81px] w-[204px] relative object-contain z-[1]"
        loading="lazy"
        alt=""
        src="/logodark@2x.png"
      />
    </header>
  );
};

export default Navbars;
